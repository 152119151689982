import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaHeart, FaComments, FaUserFriends, FaApple, FaGooglePlay, FaAndroid, FaDownload } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

// 修改确认对话框组件
function DownloadConfirmDialog({ isOpen, onClose, onConfirm }) {
    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
            <div className="bg-gradient-to-br from-yellow-50 to-yellow-100 rounded-2xl p-8 max-w-md w-full shadow-xl border border-yellow-200">
                <div className="flex items-center mb-6">
                    <FaAndroid className="text-4xl text-[#9333EA] mr-4" />
                    <h3 className="text-2xl font-bold text-gray-800">{t('androidBetaDownload')}</h3>
                </div>

                <p className="text-gray-700 text-lg leading-relaxed mb-8">
                    {t('androidInstallTips')}
                </p>

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-6 py-3 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium transition-colors duration-200 flex items-center"
                    >
                        取消
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-6 py-3 rounded-full bg-[#9333EA] hover:bg-[#7E22CE] text-white font-medium transition-colors duration-200 flex items-center shadow-lg hover:shadow-xl transform hover:scale-105"
                    >
                        <FaDownload className="mr-2" />
                        开始下载
                    </button>
                </div>
            </div>
        </div>
    );
}

function DownloadPage() {
    const { t } = useTranslation();
    const [showDownloadConfirm, setShowDownloadConfirm] = useState(false);

    useEffect(() => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile) {
            // 延迟更长时间，让用户先看到标题和描述
            setTimeout(() => {
                window.scrollTo({
                    top: window.innerHeight * 0.4, // 减小滚动距离，只显示部分下载按钮
                    behavior: 'smooth',  // 平滑滚动
                    duration: 2000  // 增加滚动时间
                });
            }, 2000);  // 延迟2秒后开始滚动
        }
    }, []);

    const handleGooglePlayClick = (e) => {
        e.preventDefault();
        const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.liujian.cook_talk';
        window.open(googlePlayUrl, '_blank');
    };

    const handleAppStoreClick = (e) => {
        e.preventDefault();
        const appStoreUrl = 'https://apps.apple.com/app/combome-find-your-mate/id6482998819';
        const mobileAppStoreUrl = 'itms-apps://itunes.apple.com/app/id6482998819';

        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            window.location.href = mobileAppStoreUrl;
            setTimeout(() => {
                window.location.href = appStoreUrl;
            }, 500);
        } else {
            window.open(appStoreUrl, '_blank');
        }
    };

    const handleCDNDownload = (e) => {
        e.preventDefault();
        setShowDownloadConfirm(true);
    };

    const handleConfirmDownload = () => {
        const cdnUrl = 'http://cdn.cooktalk.top/apk/tiemev2.3.1.apk';
        setShowDownloadConfirm(false);
        window.open(cdnUrl, '_blank');
    };

    return (
        <main className="min-h-screen bg-transparent py-16 px-4">
            <div className="container mx-auto max-w-5xl">
                <header className="text-center mb-20">
                    <h1 className="text-7xl font-black text-purple-600 mb-8 tracking-tight drop-shadow-lg"
                        style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.1)' }}>
                        {t('appName')}
                    </h1>
                    <span className="sr-only">贴密(ComboMe) - 树洞 | 漂流瓶 | 匿名社交 | 倾诉心声</span>

                    <h2 className="text-4xl font-semibold text-gray-800 mb-16 leading-relaxed"
                        style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.1)' }}>
                        {t('slogan')}
                    </h2>

                    <p className="text-2xl text-gray-700 leading-relaxed mb-16 max-w-3xl mx-auto"
                        style={{ textShadow: '1px 1px 1px rgba(0,0,0,0.05)' }}>
                        {t('description')}
                    </p>

                    <nav className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
                        <button
                            onClick={handleAppStoreClick}
                            className="flex items-center justify-center px-10 py-5 text-xl font-bold rounded-full text-white bg-black hover:bg-gray-800 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
                            <FaApple className="mr-3 text-3xl" /> {t('downloadOnAppStore')}
                        </button>
                        <button
                            onClick={handleGooglePlayClick}
                            className="flex items-center justify-center px-10 py-5 text-xl font-bold rounded-full text-white bg-[#00C853] hover:bg-[#00B84D] transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
                            <FaGooglePlay className="mr-3 text-3xl" /> {t('getItOnGooglePlay')}
                        </button>
                        <button
                            onClick={handleCDNDownload}
                            className="flex items-center justify-center px-10 py-5 text-xl font-bold rounded-full text-white bg-[#9333EA] hover:bg-[#7E22CE] transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
                            <FaAndroid className="mr-3 text-3xl" /> {t('androidBetaDownload')}
                        </button>
                    </nav>
                </header>

                <section className="grid md:grid-cols-3 gap-12 mb-24 bg-transparent" aria-label="应用特性">
                    <FeatureCard
                        icon={<FaHeart className="text-6xl text-pink-500" />}
                        title={t('features.item1Title')}
                        description={t('features.item1')}
                        color="pink"
                    />
                    <FeatureCard
                        icon={<FaComments className="text-6xl text-blue-500" />}
                        title={t('features.item2Title')}
                        description={t('features.item2')}
                        color="blue"
                    />
                    <FeatureCard
                        icon={<FaUserFriends className="text-6xl text-green-500" />}
                        title={t('features.item3Title')}
                        description={t('features.item3')}
                        color="green"
                    />
                </section>

                <footer className="text-center">
                    <p className="text-4xl font-bold text-purple-600 mb-6"
                        style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.1)' }}>
                        {t('cta')}
                    </p>
                </footer>

                {/* 添加确认对话框 */}
                <DownloadConfirmDialog
                    isOpen={showDownloadConfirm}
                    onClose={() => setShowDownloadConfirm(false)}
                    onConfirm={handleConfirmDownload}
                />

                <ToastContainer />
            </div>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "SoftwareApplication",
                        "name": "贴密(ComboMe)",
                        "applicationCategory": "SocialNetworkingApplication",
                        "operatingSystem": ["iOS", "Android"],
                        "offers": {
                            "@type": "Offer",
                            "price": "0",
                            "priceCurrency": "CNY"
                        },
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "4.8",
                            "ratingCount": "1000",
                            "bestRating": "5",
                            "worstRating": "1"
                        },
                        "description": "一款真实的社交App，支持树洞倾诉、漂流瓶、匿名聊天、话题卡片、群聊等功能。让你找到志同道合的朋友，分享生活，倾诉心声。",
                        "downloadUrl": "https://cooktalk.top",
                        "featureList": [
                            "树洞倾诉",
                            "漂流瓶",
                            "匿名聊天",
                            "话题卡片",
                            "群聊交友"
                        ]
                    })}
                </script>
            </Helmet>
        </main>
    );
}

function FeatureCard({ icon, title, description, color }) {
    const gradientClass = {
        pink: 'from-pink-400/30 to-pink-600/30',
        blue: 'from-blue-400/30 to-blue-600/30',
        green: 'from-green-400/30 to-green-600/30'
    }[color];

    return (
        <div className={`bg-gradient-to-br ${gradientClass} rounded-3xl shadow-xl backdrop-blur-lg transform transition duration-300 hover:scale-105 overflow-hidden p-10 flex flex-col items-center`}>
            <div className="mb-8 p-5 rounded-full">{icon}</div>
            <h3 className="text-2xl font-bold text-gray-800 mb-6"
                style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.1)' }}>{title}</h3>
            <p className="text-gray-700 text-lg leading-relaxed text-center"
                style={{ textShadow: '0.5px 0.5px 1px rgba(0,0,0,0.05)' }}>{description}</p>
        </div>
    );
}

export default DownloadPage;
